import React from 'react';

import SidebarData from "./SidebarData";



// import Zehad from 'Zehad'

function SideBar() {
   return (
      
         <div className="Sidebar">
            <SidebarData />
         </div>
    
   );
   
}




export default SideBar;

