import {StrictMode} from 'react';
import React from 'react';
import {createRoot} from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// import * as serviceWorker from './serviceWorker';
root.render(
    <StrictMode>
        <App />
    </StrictMode>,
);
// serviceWorker.register();
reportWebVitals();
