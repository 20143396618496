import React from 'react'
import '../App.css';
import {Link} from 'react-router-dom';
// import { Switch } from 'react-router-dom';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'

// import '../App.css';
import Projects from '../pages/Projects'
import Contact from '../pages/Contact'
// import Blog from '../pages/Blog'
import HomepageContent from '../Components/HomepageContent'
import SocialButton from './SocialButton'
import HomeIcon from '@material-ui/icons/Home';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import GitHubIcon from '@material-ui/icons/GitHub';
import LinkedIn from '@material-ui/icons/LinkedIn';
// import EmailIcon from '@material-ui/icons/Email';
import EmailIcon from '@material-ui/icons/Email';
// import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

// eslint-disable-next-line
import * as path from "path";
// import SearchIcon from '@material-ui/icons/Search';

// import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';

// export default SidebarData


export default function SidebarData() {
    return (
        <>
            <Router>

                <ul className="SidebarList">

                    <li className="row" >
                        <Link exact="true" to="/"> <HomeIcon/> </Link>
                    </li>


                    <li className="row" id="">
                        <Link to="#"> <Brightness4Icon/> </Link>
                    </li>

                    <li className="row" id="">
                        <Link to="/contact"> <EmailIcon/> </Link>
                    </li>

                    {/* <li className="row" id="">
              <Link to="/Contact">  <ImportContactsIcon/ > </Link>
            </li> */}

                    <li className="row" id="">
                        <Link to="/Projects"> <PhotoLibraryIcon/> </Link>
                    </li>


                    {/* <li className="row" >
              <Link to="/projects">
              <SearchIcon/ >
              </Link>
            </li> */}


                    <li className="row">
                        {/* <Link to="https://github.com/zehadkhan"> <GitHubIcon/ > </Link> */}
                        <a href="https://github.com/zehadkhan" target="_blank" rel="noopener noreferrer"> <GitHubIcon />
                        </a>
                    </li>

                    <li className="row">
                        {/* <Link to="https://github.com/zehadkhan"> <GitHubIcon/ > </Link> */}
                        <a href="https://www.linkedin.com/in/zehad-khan" target="_blank" rel="noopener noreferrer"> <LinkedIn />
                        </a>
                    </li>

                    {/* <li className="row">
              <Link to="/about">A</Link>
            </li> */}

                    <li>
                        <div className="social-menu"><SocialButton/></div>
                    </li>
                </ul>


                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}


                <div className="output">
                    <Routes>

                        {/* <Route path="/about">
              <about />
              About
            </Route> */}

                        {/* <Route path="/github">
              <GitHubIcon />
              Github
            </Route> */}

                        {/* <Route path="/ProjectCom">
              <Projects />
              search
            </Route> */}

                        <Route path="/projects" element={<Projects/>}/>


                        {/* <Route path="/contact">

              Contact
            </Route> */}


                        <Route path="/contact" element={<Contact/>}/>

                        <Route path="/Projects" element={<Projects/>}/>


                        <Route path="/#" element={<ui/>}/>

                        <Route path="/" element={<HomepageContent/>}/>


                    </Routes>
                </div>

            </Router>

        </>
    )
}