import React, {useEffect} from 'react';
import Iframe from 'react-iframe'
import * as jQuery from 'jquery';
import '../App.css'
// import { StrictMode } from "react";
import {createRoot} from "react-dom/client";
import {useTheme} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import playerCovered from '../Assets/images/thousand-years.jpg'
import playAudioFile from '../Assets/Audio/ChristinaPerri.mp3'

// import {element} from "prop-types";

var musicListArr = {
    // song: {},
    "songs": [
        {
            id: 0,
            "title": "A Thousand Years",
            "artist": "Christina Perri",
            "coverd": playerCovered,
            "url": playAudioFile,
            "length": "5:15"
        },
        {
            id: 1,
            "title": "Morning Mr Magpie",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://cdn.pixabay.com/audio/2022/08/03/audio_54ca0ffa52.mp3",
            "length": "4:41"
        },
        {
            id: 2,
            "title": "Luke Bergs Golden State",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://www.chosic.com/wp-content/uploads/2022/03/Luke-Bergs-Golden-State-of-Mind_MasterMP3.mp3",
            "length": "4:41"
        },
        {
            id: 3,
            "title": "You Deserve Better Than Me",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://www.chosic.com/wp-content/uploads/2021/06/Smart.mp3",
            "length": "4:41"
        },
        {
            id: 4,
            "title": "Tera Chehra",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://media.hungama.com/c/4/18e/5e2/280932/280932_128.mp3?LcqkFuiGdI-1wb0Uymg23n8z6uspu7UKHUuE-W9eRGpgkPPziVoB3uYE1CEYDlJqg52wJ77qW5Xz5WDIiFy0YnitaDE6gXNMZHkwZ1KdmM_-BnOMQWujY-ZZuKXrTpB3",
            "length": "4:41"
        },
        {
            id: 5,
            "title": "Tum Mile - Hindi Song",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://pagalworld.com.se/siteuploads/files/sfd5/2164/Tum%20Mile%20(Unplugged%20Cover)(PagalWorld.com.se).mp3",
            "length": "4:41"
        },
        {
            id: 6,
            "title": "Bin Tere - ",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://media.hungama.com/c/4/ffa/4fa/41934996/41934996_128.mp3?yYLxF14r2ybxEsWPB_rNx64022P_aijTA9hhbfmPNHdyAZnAARD81Q1Ekm9-B3gmC4EQr-RgMaZjpeonhNpI0z2xWFcsSjDqng_V9WJDCjciGEATkaKgbhooaQcG1WV-Tn-yqA",
            "length": "4:41"
        }
        ,
        {
            id: 7,
            "title": "Ranjha ",
            "artist": "unknown",
            "coverd": "https://m.media-amazon.com/images/I/A1g4NVJ5KsL._SS500_.jpg",
            "url": "https://media.hungama.com/c/4/f69/915/72340300/72340300_128.mp3?VJ9tyW6pwWZZnZF0nVMBfDK6n2HbNxzGkun2hjj4fiTXjUB1M8XdcTvsgVOcruytfq1pau246Upx3y0XkoDwrCQJTVpHCAUQNogqq5lJEDU_-QXTLhklnp3CABbjd22CPv0PXw",
            "length": "4:41"
        }
    ],
};

window.addEventListener("offline", (event) => {
    const statusDisplay = document.getElementById("status");
    statusDisplay.textContent = "OFFline";
});

window.addEventListener("online", (event) => {
    const statusDisplay = document.getElementById("status");
    statusDisplay.textContent = "Online";
});

const MusicLoadOnClick = element => {
    let SongNameByList = jQuery('.song-name-bylist');
    let artistNameBySong = jQuery('.artist-name-by-song');
    let SongCoverd = jQuery('.music-card-coverd');
    console.log("loaded succesfull");

    var jsonId = 0;
    let audioPlay = document.getElementById('music-file-show-audio');
    audioPlay.load();
    audioPlay.currentTime = 0;
    var nopromise = {
        // eslint-disable-next-line no-new-func
        catch: new Function()
    };
    (audioPlay.play() || nopromise).catch(function () {
    });


    let PlayicoControl = document.getElementById('play-control-ico');
    let pauseIcoControl = document.getElementById('pause-control-ico');

    if (audioPlay.play) {
        PlayicoControl.style.display = 'none';
        pauseIcoControl.style.display = 'block';
        console.log("paused true");
    } else {
        pauseIcoControl.style.display = 'none';
        PlayicoControl.style.display = 'block';
        console.log("paused false");
    }
    let idNumOfAttr = element.target.attributes.getNamedItem("data-id").value;
    console.log(idNumOfAttr);

    jQuery('.single-music-inner').children().removeClass('play-list-style afterchild-of-single-music');
    // jQuery(this).removeClass('c');

    // console.log("fghjkfghjkfcgvhbjnghjgvhb "+);
    let getClickedId = element.currentTarget.className;
    let playIdClassConCat = getClickedId.toString();
    jQuery("." + playIdClassConCat).addClass('play-list-style afterchild-of-single-music');
    // console.log(playIdClassConCat)
    // eslint-disable-next-line no-self-compare
    if (idNumOfAttr === idNumOfAttr) {
        console.log("true " + audioPlay.duration);
        jsonId = idNumOfAttr;
        jQuery('#music-file-show-audio').children().remove();
        jQuery('#music-file-show-audio').append(`<source class="music-file-source" src=${musicListArr.songs[`${jsonId}`].url} type="audio/mp3">`);
        jQuery(SongNameByList).text(`${musicListArr.songs[`${jsonId}`].title}`);
        jQuery(artistNameBySong).text(`${musicListArr.songs[`${jsonId}`].artist}`).wrapInner('<marquee  behavior="scroll" direction="left" scrollamount="2"></marquee>');
        jQuery(SongCoverd).attr("src", `${musicListArr.songs[`${jsonId}`].coverd}`);
    } else {
        console.log("unknown");
        // audioPlay.load();
        audioPlay.pause();
        return 0;
    }
}

function Projects() {

    const theDownloader = () => {
        let input_URL = document.getElementById('input_field_value').value;
        // let vidUri = window.prompt("Please enter the video url", '');
        console.log(input_URL);
        const options = {
            method: 'GET', headers: {
                'X-RapidAPI-Key': 'a68c51e3aemsh0df103efaf9b27ap1dbd49jsnd09acdf7b0c1',
                'X-RapidAPI-Host': 'tiktok-downloader-download-tiktok-videos-without-watermark.p.rapidapi.com'
            }
        };

// console.log(vidUri);

        fetch(`https://tiktok-downloader-download-tiktok-videos-without-watermark.p.rapidapi.com/vid/index?url=${input_URL}`, options)
            .then(response => response.json())
            .then(response => {
                console.log(response.video[0])
                createRoot(document.getElementById('video_download_btn_show')).render(
                    <a rel="noopener noreferrer" target="_blank" href={response.video[0]} download style={{
                        zIndex: '9',
                        "color": "#fff",
                        "border": "1px solid",
                        "padding": "6px",
                        "position": "relative",
                        "top": "1em",
                        "fontSize": "12px",
                        "background": "#1ab100",
                        "borderRadius": "5px",
                    }}> Download
                    </a>);
                createRoot(document.getElementById('video_download_video_show')).render(<video
                    style={{maxHeight: "240px", width: "100%"}} src={response.video[0]}></video>)
            })
            .catch(err => console.error(err));
    }
    const YouTubeGetID = () => {
        let ytInputURL = document.getElementById('input_field_value_yt').value;
        ytInputURL = ytInputURL.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        const options = {
            method: 'GET', headers: {
                'X-RapidAPI-Key': '1bb279a246mshd84ab86615fe035p103abcjsnb6d78bafa82c',
                'X-RapidAPI-Host': 'youtube-media-downloader.p.rapidapi.com'
            }
        };
        // eslint-disable-next-line
        let vidId = (ytInputURL[2] !== undefined) ? ytInputURL[2].split(/[^0-9a-z_\-]/i)[0] : ytInputURL[0];

        fetch(`https://youtube-media-downloader.p.rapidapi.com/v2/video/details?videoId=${vidId}`, options)
            .then(response => response.json())
            .then(response => {
                console.log(response);
                // console.log(response.videos.items.map((element) => {
                let utr = [];
                utr.push(response.videos.items);
                console.log(utr);
                let vidItemUriArr = utr[0].length;
                jQuery('#yt_video_download_video_show').append('<div><h6 style="font-weight: 800; font-size: 14px">Title: ' + response.title + '</h6><div style="position: relative;overflow: scroll; height: 225px;" id="zkk' + vidItemUriArr + '">' + response.videos.items.map((linkGen) => {
                    // console.log(linkGen.sizeText)
                    // return linkGen.sizeText + '<br />' +  linkGen.url;
                    return `<a href=${linkGen.url} style="display: flex;justify-content: space-around;align-items: center;">
                               <video src=${linkGen.url} style="height: 70px; margin: 0 0;left: 0;"></video>  ${linkGen.sizeText} 
                                <button style=" z-index: 9;color: rgb(255, 255, 255);border: 1px solid;padding: 6px;position: relative; font-size: 12px;background: rgb(26, 177, 0);border-radius: 5px;"> 
                                   Download
                                </button><br />
                            </a>`;
                }) + '</div></div>');
            })
            .catch(err => console.error(err));
    }
    const FacebookVideoDownload = () => {

        // https://facebook-reel-and-video-downloader.p.rapidapi.com/app/main.php?url=https://www.facebook.com/100005426610331/videos/1056828144973368/
        let fbUrl = document.getElementById('facebook_input_field_value').value;
        // function isEmptyOrSpaces(str){
        //     return str === null || str.match(/^ *$/) !== null;
        // }
        fbUrl = fbUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
        let fbTokenUrli = `https://facebook-reel-and-video-downloader.p.rapidapi.com/app/main.php?url=https%3A%2F%2F${fbUrl}`;
        console.log(fbUrl);

        if (fbUrl !== " ") {
            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': 'a68c51e3aemsh0df103efaf9b27ap1dbd49jsnd09acdf7b0c1',
                    'X-RapidAPI-Host': 'facebook-reel-and-video-downloader.p.rapidapi.com'
                }
            };
            fetch(fbTokenUrli, options)
                .then(response => response.json())
                .then(response => {
                    console.log("High Quality :" + response.links['Download High Quality']);
                    console.log("Low Quality :" + response.links['Download Low Quality']);

                    // jQuery('#facebook-video_download_btn_show').append(`<div style="color: #1b1e21"> ${response.title} </div>`);
                    jQuery('#facebook-video_download_btn_show').append(`<div><h6 style="font-weight: 800; font-size: 14px; color: black ">Title: ${response.title}</h6>
                        <a href=${response.links['Download Low Quality']} style="display: flex;justify-content: space-around;align-items: center;"> 
                        <button style=" z-index: 9;color: rgb(255, 255, 255);border: 1px solid;padding: 6px;position: relative; font-size: 12px;background: rgb(26, 177, 0);border-radius: 5px;">Download</button>
                        <br /></a></div>`);
                    jQuery('#facebook-video_download_video_show').append(`<video style=" width: 136px;position: absolute;left: 0;" src=${response.links['Download Low Quality']}> </video>`);
                })
                .catch(err => console.error(err));
        } else {
            alert("please use Correct video url");
        }

        // useEffect(()=>{
        //
        // })

    }

    jQuery(document).ready(function () {
        console.log("document is ready");
        jQuery('#input_field_value_yt ,#input_field_value').on('click', function () {
            let ytInput = document.querySelectorAll('#input_field_value_yt, #input_field_value').value;
            if ((ytInput === '') || (ytInput === null) || (ytInput === true)) {
                jQuery('#input_field_value_yt , #input_field_value').val("");
            } else {
                // return; ytInput="";
                jQuery('#input_field_value_yt ,#input_field_value').val("");
            }
            console.log("clicked");
        });
        jQuery(window).on('load', function () {
            playAudioSound();
        });
    })


    const PlayNext = (element) => {
        useEffect(() => {
            createRoot(document.getElementById('music-list-innerid')).render(
                // eslint-disable-next-line array-callback-return
                musicListArr.songs.map((data, key) => {
                    console.log(key);
                    console.log(data);
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid,no-script-url
                    return <div className="single-music-inner"> {key + 1}<a style={{
                        maxHeight: "240px",
                        width: "100%",
                        color: '#FFFFFF',
                        fontSize: '14px',
                        fontWeight: '700',
                        fontFamily: 'monospace',
                        marginLeft: '8px',
                        zIndex: '99',
                        // eslint-disable-next-line no-script-url
                    }} href={"javascript:void(0)"} onClick={MusicLoadOnClick}
                                                                            data-id={data.id}
                                                                            className={"child-of-single-music_" + data.id}>{data.title}</a>
                    </div>;
                    // jQuery(ss).append(`<div class="single-music-inner" >${key + 1}: <a href="javascript:void(0);" data-id=${data.id} class="child-of-single-music" data-url=${data.url}> ${data.title}</a></div>`);
                })
            )
        }, [])
    }

    const playAudioSound = () => {
        let audioPlay = document.getElementById('music-file-show-audio');
        let PlayicoControl = document.getElementById('play-control-ico');
        let pauseIcoControl = document.getElementById('pause-control-ico');
        let getMusicAudio = Math.floor(audioPlay.duration) / 60;
        // let getMusicAudioRoundTime = Math.floor(getMusicAudio * 100) / 100;
        // console.log("dur " + audioPlay.duration);

        console.log(getMusicAudio);

        if (audioPlay.paused) {
            // PauseIcon
            PlayicoControl.style.display = 'none';
            pauseIcoControl.style.display = 'block';
            console.log("play done");
            audioPlay.play();
        } else {

            console.log("paused done");
            audioPlay.pause();
            pauseIcoControl.style.display = 'none';
            PlayicoControl.style.display = 'block';
        }
        setInterval(function () {
            setTimeout(myFunction, 1000);
            let getMusicAudioCurrent = Math.floor(audioPlay.currentTime) / 60;
            getMusicAudioCurrent = Math.floor(getMusicAudioCurrent * 100) / 100;
            let finalSizeOfMusic = getMusicAudio.toFixed(2) % 60;
            jQuery('.music-level-time-show').children().remove();
            // jQuery('.music-level-time-show').append(`<pre>${getMusicAudioCurrent}</pre> <pre>${Math.floor(audioPlay.duration %60)}</pre>`);
            jQuery('.music-level-time-show').append(`<pre>${getMusicAudioCurrent.toFixed(2).toString().replace('.', ':')}</pre> <pre>${finalSizeOfMusic}</pre>`);

            function myFunction() {
                // let defineFullVidWid = audioPlay.duration;
                let StyledefineFullVidWid = 100;
                jQuery('.sound-progress').css('maxWidth', StyledefineFullVidWid + "%");
                // let defVidPlayCurrentTime = audioPlay.currentTime;
                // console.log("Now Play Time " + defVidPlayCurrentTime);
                // console.log(defineFullVidWid + "full time seconds");

                // defVidPlayCurrentTime = 1;
                // let interval = setInterval(function() {
                //     if (defVidPlayCurrentTime <= defineFullVidWid) {
                //         // console.log("tytuyggf "+defVidPlayCurrentTime*60/defVidPlayCurrentTime);
                //         // console.log("last action time "+defVidPlayCurrentTime);
                //         // console.log("last action full time "+defineFullVidWid);
                //         // console.log("Devided by "+defVidPlayCurrentTime);
                //         jQuery('.sound-progress').css('width',defVidPlayCurrentTime+++"%");
                //     }
                //     else {
                //         clearInterval(interval);
                //     }
                // }, 1000);
            }

        }, 1000);
    }

    const theme = useTheme();
    return (

        <div className="ProjectCom">
            <div className="project_Heading">
                <h1>Learn More About Me</h1>
            </div>
            <div className="pro_inner" style={{marginBottom: '8em', fontFamily: 'sans-serif'}}>
                <div>
                    <h3>COvid-19 Update</h3>
                    <Iframe url="https://portfolio.zehadkhan.com/corona-update/"

                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://portfolio.zehadkhan.com/corona-update/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>


                <div>
                    <h3> E-store </h3>
                    {/*<Iframe url="https://e.zehadkhan.com"*/}
                    <Iframe url="https://e.zehadkhan.com"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://e.zehadkhan.com/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>

                <div>
                    <h3>Attendance </h3>
                    <Iframe url="https://attendance.zehadkhan.com/"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://attendance.zehadkhan.com/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>
                <div>
                    <h3> Store </h3>
                    <Iframe url="https://store.zehadkhan.com/"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://store.zehadkhan.com/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>
                <div>
                    <h3> Subscribe </h3>
                    <Iframe url="https://subscribe.zehadkhan.com/"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://subscribe.zehadkhan.com/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>

                <div>
                    <h3> Search System </h3>
                    <Iframe url="https://search.zehadkhan.com/"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://search.zehadkhan.com/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>
                <div>
                    <h3> Dental Care</h3>
                    <Iframe url="https://smiledentalcarebd.com/"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://smiledentalcarebd.com/"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>
                <div>
                    <h3>PabnaCityBazar </h3>
                    <Iframe url="https://www.pabnacitybazar.com"
                            width="350px"
                            height="340px"
                            id="myId"
                            className="myClassname"
                            display="initial"
                            position="relative"/>
                    <div className="goto_site_liv">
                        <a className="project_folder_url" href="https://www.pabnacitybazar.com"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>
                <div style={{height: '340px', width: '340px'}} className="tiktok-downloader-section">
                    <h4 style={{fontSize: '1.75rem !important'}}>Download TikTok video without waterMark </h4>
                    <div className="video-frame" style={{
                        height: '325px',
                        width: '340px',
                        border: '2px solid #fff',
                        background: 'rgb(255, 255, 255)'
                    }}>
                        <div className="water-mark-remove">
                            <div id="download-btn" style={{
                                display: 'flex', justifyContent: 'space-around', textAlign: 'center', marginTop: '10px'
                            }}>
                                <form action="">
                                    <input style={{
                                        borderRadius: '5px',
                                        fontSize: '14px',
                                        width: '200px',
                                        height: '40px',
                                        border: '1.5px solid rgb(238 85 160)',
                                    }} id="input_field_value" name="input_field_value" type="text"
                                           placeholder="Enter The Video Url"/>
                                </form>
                                <button id="video_download_btn" onClick={theDownloader} style={{
                                    border: '1px solid',
                                    background: 'rgb(255 10 103)',
                                    color: '#fff',
                                    width: '82px',
                                    padding: '2px',
                                    fontSize: '14px',
                                    borderRadius: '4px'
                                }}>Enter
                                </button>
                            </div>
                            <div id="video_download_btn_show"></div>
                            <div id="video_download_video_show"></div>
                        </div>
                    </div>
                    <div className="goto_site_liv">
                        <a style={{padding: '23px 134px'}} className="project_folder_url" href="!#"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>

                <div style={{height: '450px', width: '340px', overflow: 'hidden', marginTop: '2em'}}
                     className="youtube-downloader-section">
                    <h4 style={{fontSize: '1.75rem !important', margin: '1em 0'}}>Download Youtube Video </h4>
                    <div className="yt-video-frame" style={{
                        height: '325px',
                        width: '340px',
                        border: '2px solid #fff',
                        background: '#FFFFFF',
                        color: '#000'
                    }}>
                        <div className="youtube-mark-video">
                            <div id="yt-download-btn" style={{
                                display: 'flex', justifyContent: 'space-around', textAlign: 'center', marginTop: '10px'
                            }}>
                                <form action="">
                                    <input style={{
                                        borderRadius: '5px',
                                        fontSize: '14px',
                                        width: '200px',
                                        height: '40px',
                                        border: '1.5px solid #72a13d'
                                    }} id="input_field_value_yt" name="input_field_value_yt" type="text"
                                           placeholder="Enter The Youtube Url"/>
                                </form>
                                <button id="youtube_video_download_btn" onClick={YouTubeGetID} style={{
                                    border: '1px solid',
                                    background: '#0a7cff',
                                    color: '#fff',
                                    width: '82px',
                                    padding: '2px',
                                    fontSize: '14px',
                                    borderRadius: '4px'
                                }}>Get Video
                                </button>
                            </div>
                            <div id="yt_video_download_btn_show" style={{position: 'relative'}}></div>
                            <div id="yt_video_download_video_show"></div>
                        </div>
                    </div>
                    <div className="goto_site_liv">
                        <a style={{padding: '23px 134px'}} className="project_folder_url" href="!#"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>


                <div style={{height: '450px', width: '540px', overflow: 'hidden', marginTop: '2em'}}
                     className="music-player-section">
                    <h4 style={{fontSize: '1.75rem !important', margin: '1em 0'}}>Music Player</h4>
                    <div className="mp-palyer-js" style={{
                        height: '370px',
                        maxWidth: '540px',
                        width: '100%',
                        border: '1px solid #fff',
                        // background: 'rgb(0 0 0)',
                        color: '#000',
                        borderRadius: '10px',
                    }}>
                        <Card sx={{
                            display: 'flex', margin: ' 0 auto',
                            maxWidth: '400px',
                            marginTop: '25px',
                            width: '96%',
                        }}>
                            <Box sx={{display: 'flex', flexDirection: 'column', width: "60%"}}>
                                <CardContent sx={{flex: '1 0 auto'}}>
                                    <Typography component="div" variant="h5" className="song-name-bylist">
                                        A Thousand Years
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" component="div"
                                                className="artist-name-by-song">
                                        Christina Perri
                                    </Typography>
                                </CardContent>


                                <div id="music-file-show">
                                    <div className="meter-animate" style={{}}>
                                        <span className="sound-progress" style={{}}></span>
                                    </div>
                                    <div className="music-level-time-show w3-green" style={{
                                        "display": "flex",
                                        "justifyContent": "space-between",
                                        "margin": "0 auto",
                                        "maxWidth": "148px",
                                        // "borderTop": "2px solid",
                                        "background": "transparent",
                                        "padding": " 2px 5px",
                                    }}>
                                        <pre className="play-time-music">0:0</pre>
                                        <pre className="full-time-music">0:00</pre>
                                    </div>
                                    <audio id="music-file-show-audio" controls={false}>
                                        {/*playAudioFile*/}
                                        {/*<source className="music-file-source" src="" type="audio/mp3"/>*/}
                                    </audio>
                                </div>


                                <Box sx={{display: 'flex', alignItems: 'center', pl: 1, pb: 1}}>
                                    <IconButton aria-label="previous">
                                        {theme.direction === 'rtl' ? <SkipNextIcon/> : <SkipPreviousIcon/>}
                                    </IconButton>
                                    <IconButton aria-label="play/pause">
                                        <PlayArrowIcon id="play-control-ico" onClick={playAudioSound}
                                                       sx={{height: 38, width: 38}}/>
                                        <PauseIcon style={{display: 'none'}} id="pause-control-ico"
                                                   onClick={playAudioSound} sx={{height: 38, width: 38}}/>
                                    </IconButton>
                                    <IconButton aria-label="next">
                                        {theme.direction === 'rtl' ? <SkipPreviousIcon/> : <SkipNextIcon/>}
                                    </IconButton>
                                </Box>
                            </Box>
                            <CardMedia className="music-card-coverd" style={{width: "40%"}}
                                       component="img"
                                       sx={{width: 151}}
                                       image={playerCovered}
                                       alt="Live from space album cover"
                            />
                        </Card>
                        <div className="music-list-section">
                            {/*eslint-disable-next-line*/}
                            <div style={{
                                "margin": "5px 2px 5px 0em",
                                "color": "rgb(255, 255, 255)",
                                "fontSize": "17px",
                                "fontWeight": "800"
                            }}><span role="img" aria-label="music">🎶</span>Song Lists<span role="img"
                                                                                           aria-label="music">🎶</span>
                            </div>
                            <div className="music-list-inner" id="music-list-innerid">

                                <PlayNext/>

                            </div>
                        </div>
                    </div>
                    <div className="goto_site_liv" style={{display: 'none'}}>
                        <a style={{padding: '23px 134px'}} className="project_folder_url" href="!#"
                           rel="noreferrer noopener nofollow" target='_blank'>View Live</a>
                    </div>
                </div>


                <div style={{height: '340px', width: '340px', marginTop: '4em'}}
                     className="facebook-downloader-section">
                    <h4 style={{fontSize: '1.75rem !important'}}>Download Facebook Videos</h4>
                    <div className="video-frame" style={{
                        height: '325px',
                        width: '340px',
                        border: '2px solid #fff',
                        background: 'rgb(255, 255, 255)'
                    }}>
                        <div className="water-mark-remove">
                            <div id="download-btn" style={{
                                display: 'flex', justifyContent: 'space-around', textAlign: 'center', marginTop: '10px'
                            }}>
                                <form action="">
                                    <input style={{
                                        borderRadius: '5px',
                                        fontSize: '14px',
                                        width: '200px',
                                        height: '40px',
                                        border: '1.5px solid rgb(10 139 255)',
                                    }} id="facebook_input_field_value" name="facebook-input_field_value" type="text"
                                           placeholder="Enter The Video Url"/>
                                </form>
                                <button id="facebook-video_download_btn" style={{

                                    border: '1px solid',
                                    background: 'rgb(10 139 255)',
                                    color: '#fff',
                                    width: '82px',
                                    padding: '2px',
                                    fontSize: '14px',
                                    borderRadius: '4px'
                                }} onClick={FacebookVideoDownload}>Enter

                                </button>
                            </div>
                            <div id="facebook-video_download_btn_show" style={{position: 'relative'}}></div>
                            <div id="facebook-video_download_video_show" style={{position: 'relative'}}></div>
                        </div>
                    </div>
                    {/*<div className="goto_site_liv">*/}
                    {/*    <a style={{padding: '23px 134px'}} className="project_folder_url" href="!#"*/}
                    {/*       rel="noreferrer noopener nofollow" target='_blank'>View Live</a>*/}
                    {/*</div>*/}
                </div>

            </div>
            {console.clear()}

            <div id="status"></div>
        </div>
    )
}

export default Projects;
