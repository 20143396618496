import React from 'react'

import {createRoot} from 'react-dom/client';


import '../App.css'

function Headline() {

    const element = <h4 className=" not-send-mail">Not SEND !</h4>;
    createRoot(document.getElementById('message-sender')).render(element)

}

function Contact() {
    fetch("https://jsonplaceholder.typicode.com/photos")
        .then(res => res.json())
        // eslint-disable-next-line
        .then(dataw => dataw.map((elements) => {

            }
        ))
        .catch((error) => console.log("Not Found"));

    const encodedParams = new URLSearchParams();
    encodedParams.append("ip", "146.160.63.10");
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
            'X-RapidAPI-Key': 'a68c51e3aemsh0df103efaf9b27ap1dbd49jsnd09acdf7b0c1',
            'X-RapidAPI-Host': 'ip-location5.p.rapidapi.com'
        },
        body: encodedParams
    };

    fetch('https://ip-location5.p.rapidapi.com/get_geo_info', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    return (

        <>
            <div className="form_head">
                <h3> Hire or Contact Me</h3>
                <p>Invite me to speak at your conference or work for you</p>
            </div>
            <div className="formStyle">
                <form className _lpchecked={1}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="exampleForm.ControlInput1">Email address</label><input
                        placeholder="name@example.com" type="email" id="exampleForm.ControlInput1"
                        className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="exampleForm.ControlSelect2">
                            Your Name? </label><input placeholder="First name" id="exampleForm.ControlSelect2"
                                                      className="form-control"/>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="exampleForm.ControlTextarea1">Your Query
                            ...</label><textarea rows={3} id="exampleForm.ControlTextarea1" className="form-control"
                                                 defaultValue={""}/>
                    </div>
                </form>
                <button type="button" className="btn btn-primary" onClick={Headline}> SEND</button>
                <br/>
                <br/>
                <div id="message-sender"></div>

                <div id="image-fetch-json" className="image-fetch-json-class"></div>
            </div>

        </>
    )
}

export default Contact;
